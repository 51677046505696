@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-darkblue text-white font-body antialiased;
}

/*
 * HEADER MENU LINK
 */
.main-menu .menu-link::after {
  content: "";
  @apply opacity-0 transition-opacity block absolute top-full left-1/2 w-20 rotate-180 -translate-x-1/2 -mt-[1px] h-5 bg-white shadow-xl shadow-white/40;
}

.main-menu .menu-link:hover::after,
.main-menu .menu-link-active::after {
  @apply opacity-100;
}

/*
 * MOBILE MENU
 */
.mobile-menu {
  @apply invisible;
}

.mobile-menu-inner {
  @apply transition-all right-full opacity-0;
}

.mobile-menu.active {
  @apply visible;
}

.mobile-menu.active .mobile-menu-inner {
  @apply right-0 opacity-100;
}

.mobile-menu .menu-link {
  @apply text-4xl sm:text-5xl text-gray-400;
}

.mobile-menu .menu-link:hover {
  @apply text-white;
}

/*
 * DROPDOWN MENU
 */
.dropdown-menu {
  @apply hidden;
}

.dropdown-menu.open {
  @apply block;
}

.dropdown-menu .dm-item.active {
  @apply cursor-default;
}

#header-lang-dropdown {
  @apply absolute z-40 right-0 top-full min-w-[160px] py-2 text-center bg-white border border-darkblue;
}

#header-lang-dropdown .dm-item {
  @apply text-gray-500 cursor-pointer select-none px-6 py-2;
}

#header-lang-dropdown .dm-item.active,
#header-lang-dropdown .dm-item:hover {
  @apply text-darkblue;
}

/*
 * BUTTON ARROW ANIMATION
 */
@keyframes rarrow {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0.2em);
    opacity: 0.5;
  }
}

@keyframes tarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-0.2em);
    opacity: 0.5;
  }
}

.btn .animate-rarrow,
.btn .animate-tarrow {
  @apply transition-all;
}

.btn:hover .animate-rarrow {
  animation: rarrow 0.6s infinite alternate ease-in-out;
}

.btn:hover .animate-tarrow {
  animation: tarrow 0.6s infinite alternate ease-in-out;
}

.page-heading {
  @apply flex items-center justify-center gap-4 font-heading;
}

.page-heading::before,
.page-heading::after {
  content: "";
  @apply block w-20 h-[1px] bg-white bg-opacity-50 mt-2;
}

/*
 * References
 */
.ref-item .ref-img-dark,
.ref-item:hover .ref-img-light {
  @apply opacity-0;
}

.ref-item:hover .ref-img-dark {
  @apply opacity-100;
}

/*
 * Categories
 */
.cat-item:hover .cat-img {
  @apply scale-110;
}

.cat-item:hover .cat-name {
  @apply text-white;
}

/*
 * Products
 */
.prod-item:hover .prod-img {
  @apply scale-110;
}

.prod-item .prod-name {
  @apply opacity-0 invisible transition-all duration-700;
}

.prod-item:hover .prod-name {
  @apply opacity-100 visible;
}

.prod-thumb:not(.active) {
  @apply border-opacity-25;
}

/*
 * Pagination
 */
.pagination {
  @apply mt-12 flex flex-wrap items-center justify-center;
}

.pagination .item {
  @apply inline-flex items-center justify-center w-10 h-10 border border-gray-600 hover:border-gray-500 bg-white bg-opacity-5 hover:bg-opacity-10 -ml-[1px] select-none cursor-pointer relative hover:z-10;
}

.pagination .item.disabled {
  @apply cursor-default hover:border-gray-600 hover:bg-opacity-5 text-gray-600;
}

.pagination .item.active {
  @apply cursor-default border-gray-500 bg-opacity-10 z-20;
}
